import React, { useEffect } from 'react';
import CenterContent from "./CenterContent";
import { bindActionCreators } from "redux";
import { completeSplashScreen } from "../store/actions";
import { Box, CircularProgress, makeStyles, Theme, Typography } from "@material-ui/core";
import { connect } from "react-redux";

const mapStateToProps = (state: any) => {
    return {
        splashScreen: state.splashScreen,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        completeSplashScreen: completeSplashScreen
    }, dispatch)
}

const useStyles = makeStyles((theme: Theme) => ({
    fabProgress: {
        zIndex: 1,
    },
    wrapper: {
        position: 'relative',
        display: "inline-block"
    },
    logo: {
        animation: '$rotation 6s infinite linear',
        width: 80,
        height: 80,
        overflow: "hidden",
        boxShadow: '0px 12px 22px #3f51b5',
        borderRadius: '4em',
        padding: '0.2em',
        border: '1px solid #ccc',
        marginBottom: -80,
    },
    logoImage: {
        width: "100%",
        padding: 10
    },
    '@keyframes rotation': {
        '25%': {
            transform: 'rotate(15deg)'
        },
        '50%': {
            transform: 'rotate(0deg)'
        },
        '75%': {
            transform: 'rotate(-15deg)'
        },
        '100%': {
            transform: 'rotate(0deg)'
        }
    },
}))

const SplashScreen = (params: any) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            params.completeSplashScreen(true)
        }, 5000);
        return () => clearTimeout(timer);
    }, [params]);
    const classes = useStyles()

    if (params.splashScreen) {
        return params.children
    }

    return (
        <React.Fragment>
            <CenterContent>
                <Box pl={3} pr={3}>
                    <Box pt={1} pb={1}>
                        <Typography align="center" component="div">
                            <div className={classes.wrapper}>
                                <div className={classes.logo}>
                                    <img className={classes.logoImage}
                                         alt="aRockets logo"
                                         src="https://arockets.ru/public/img/arockets-logo.svg"
                                    />
                                </div>
                                <CircularProgress size={80} className={classes.fabProgress}/>
                            </div>
                        </Typography>
                    </Box>
                    <Box pb={3}>
                        <Typography align="center" variant="h4">
                            aRockets
                        </Typography>
                    </Box>
                    <Typography align="center">
                        У нас собрано 10000+ промокодов для 3000+ магазинов.
                    </Typography>
                    <Typography align="center">
                        C приложением aRockets вы тратите деньги с умом!
                    </Typography>
                </Box>
            </CenterContent>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen)
