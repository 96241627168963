import React, { RefObject } from 'react';
import SearchIcon from "@material-ui/icons/Search";
import { fade, IconButton, InputBase, Theme, withStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ReactGA from "react-ga";

const styles = (theme: Theme) => {
    return {
        search: {
            position: 'relative',
            borderRadius: 15,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                width: 'auto',
            },
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: "absolute",
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        closeIcon: {
            padding: theme.spacing(0, 0, 0, 1),
            height: '100%',
            position: "absolute",
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2,
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            padding: theme.spacing(1, 1, 1, 0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            paddingRight: `calc(1em + ${theme.spacing(2)}px)`,
            transition: theme.transitions.create('width'),
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                width: '26ch',
                '&:focus': {
                    width: '44ch',
                },
            },
        },
        loading: {
            animation: '$rotation 1s infinite linear',
        },
        '@keyframes rotation': {
            from: {
                transform: 'rotate(0deg)'
            },
            to: {
                transform: 'rotate(360deg)'
            },
        },
    }
}

class ToolbarSearch extends React.Component<any, any> {

    private timer: number = -1
    private inputRef: RefObject<any>

    constructor(props: any) {
        super(props);
        this.onEnter = this.onEnter.bind(this)
        this.resetInputValue = this.resetInputValue.bind(this)
        this.inputRef = React.createRef();
        this.state = {
            searching: false
        }
    }

    onEnter(evt: any) {
        const value = evt.target.value || ""
        this.clearTimer()
        !this.state.searching && this.setState({
            searching: true
        })
        this.timer = window.setTimeout(() => {
            this.props.updateSearchQuery(value)
            this.setState({
                searching: false
            })
            if (value) {
                ReactGA.event({
                    category: "search",
                    action: "query",
                    label: value
                })
                const { storeName } = this.props
                storeName && ReactGA.event({
                    category: "search",
                    action: storeName,
                    label: value
                })
            }
        }, 1000)
    }

    clearTimer() {
        clearTimeout(this.timer)
    }

    resetInputValue() {
        this.clearTimer()
        this.props.updateSearchQuery("")
        this.inputRef.current.value = ""
        this.setState({
            searching: false
        })
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.search} id="search">
                <div className={classes.searchIcon}>
                    {this.state.searching && (
                        <AutorenewIcon className={classes.loading}/>
                    )}
                    {!this.state.searching && (
                        <SearchIcon/>
                    )}
                </div>
                <InputBase
                    inputRef={this.inputRef}
                    onChange={this.props.updateSearchQuery && this.onEnter}
                    onKeyPress={this.props.updateSearchQuery && this.onEnter}
                    placeholder="Поиск..."
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
                <div className={classes.closeIcon}>
                    <IconButton size="small" color="primary" onClick={this.resetInputValue}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </div>
        )
    }
}

// @ts-ignore
export default withStyles(styles)(ToolbarSearch)
