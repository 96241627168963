import React, { useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import MainLayout from "../layout/Main"
import Stores from "../pages/Stores";
import StoreView from "../pages/StoreView";
import NotFound from "../pages/404";
import ReactGA from "react-ga";

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS as string

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes("localhost")) {
            ReactGA.initialize(GOOGLE_ANALYTICS_ID);
        }
        setInitialized(true);
    }, []);

    useEffect(() => {
        if (initialized) {
            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};

const Routes = () => {

    usePageTracking()
    const location = useLocation();

    return (
        <Switch location={location}>
            <Route exact path={["/", "/store/:host"]}>
                <MainLayout>
                    <Switch location={location} key={location.pathname}>
                        <Route exact path="/" component={Stores}/>
                        <Route exact path="/store/:host" component={StoreView}/>
                    </Switch>
                </MainLayout>
            </Route>

            <Route path="*">
                <MainLayout>
                    <Switch location={location} key={location.pathname}>
                        <Route path="*" component={NotFound}/>
                    </Switch>
                </MainLayout>
            </Route>
        </Switch>
    )
}

export default Routes
