import {
    RECEIVE_DATA,
    UPDATE_APP_GUIDE_COMPLETE,
    UPDATE_APP_SETTINGS,
    UPDATE_DATA_CATEGORIES,
    UPDATE_SEARCH_PROMOCODE_QUERY,
    UPDATE_SEARCH_STORE_QUERY,
    UPDATE_SPLASH_SCREEN_VISIBILITY
} from "./action-types";
import { setAppCacheSettings, setAppPermanentsSettings } from "../utils";

export function receiveData(data: any) {
    return {
        type: RECEIVE_DATA,
        data
    }
}

export function updateSearchStoreQuery(query: string) {
    return {
        type: UPDATE_SEARCH_STORE_QUERY,
        searchStoreQuery: query
    }
}

export function updateSearchPromocodeQuery(query: string) {
    return {
        type: UPDATE_SEARCH_PROMOCODE_QUERY,
        searchPromocodeQuery: query
    }
}

export function updateSplashScreenVisible(visible: boolean) {
    return {
        type: UPDATE_SPLASH_SCREEN_VISIBILITY,
        splashScreen: visible
    }
}

export function completeAppGuide(complete: boolean) {
    return {
        type: UPDATE_APP_GUIDE_COMPLETE,
        completeGuide: complete
    }
}

export function updateCategories(categories: string[]) {
    return {
        type: UPDATE_DATA_CATEGORIES,
        categories
    }
}

export type AppSettings = {
    splashScreen?: boolean,
    completeGuide?: boolean
}

export function updateAppSettings(appSettings: AppSettings) {
    return {
        type: UPDATE_APP_SETTINGS,
        appSettings: {
            splashScreen: appSettings.splashScreen,
            completeGuide: appSettings.completeGuide
        }
    }
}

export function completeSplashScreen(visible: boolean) {
    return async (dispatch: any) => {
        await setAppCacheSettings({
            splashScreen: visible
        })
        dispatch(updateSplashScreenVisible(visible))
    }
}

export function completeGuide(complete: boolean) {
    return async (dispatch: any) => {
        await setAppPermanentsSettings({
            completeGuide: complete
        })
        dispatch(completeAppGuide(complete))
    }
}
