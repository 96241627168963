import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from "./store/index"
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { themeTiktok } from "./theme";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
    <ThemeProvider theme={themeTiktok}>
        <Provider store={store}>
            <CssBaseline/>
            <App/>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (process.env.REACT_APP_WPA === 'true') {
    serviceWorkerRegistration.register();
} else {
    serviceWorkerRegistration.unregister();
}
