import { AnimatePresence, motion } from 'framer-motion';
import React from 'react'

export const pageVariants = {
    initial: {
        opacity: 0,
        scale: 0.99
    },
    in: {
        opacity: 1,
        scale: 1
    },
    out: {
        opacity: 0,
        scale: 1.01
    }
};

export const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.1
};

export const AnimateSwap = ({ children }: any) => {
    return (
        <AnimatePresence exitBeforeEnter initial>
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                key={window.location.search}
                variants={pageVariants}
                transition={pageTransition}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    )
}
