import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers/index";
import thunk from "redux-thunk"
import setup from "./setup"

const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

setup().then(void (0));

export default store;


