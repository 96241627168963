import React from 'react';
import ErrorBoundary from "../components/ErrorBoundary";
import PropTypes from 'prop-types';
import { Box, Container, Paper } from "@material-ui/core";
import SplashScreen from "../components/SplashScreen";
import grey from "@material-ui/core/colors/grey";

const MainLayout = (props: any) => {
    const { children } = props;

    return (
        <React.Fragment>
            {props.listFetchError && (
                <ErrorBoundary hasError={true}/>
            )}
            {!props.listFetchError && (
                <Container disableGutters maxWidth="md">
                    <Box display="flex" flexDirection="column">
                        <Paper elevation={1} style={{
                            marginBottom: 1,
                            backgroundColor: grey[50]
                        }}>
                            <SplashScreen>
                                {children}
                            </SplashScreen>
                        </Paper>
                    </Box>
                </Container>
            )}
        </React.Fragment>
    )
};

MainLayout.propTypes = {
    children: PropTypes.node,
    listFetchError: PropTypes.bool
};

export default MainLayout;
