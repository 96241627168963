import {
    RECEIVE_DATA,
    UPDATE_APP_GUIDE_COMPLETE,
    UPDATE_APP_SETTINGS,
    UPDATE_DATA_CATEGORIES,
    UPDATE_SEARCH_PROMOCODE_QUERY,
    UPDATE_SEARCH_STORE_QUERY,
    UPDATE_SPLASH_SCREEN_VISIBILITY
} from "../actions/action-types";

const initialState = {
    appStatus: "loading",
    shopsList: [],
    favorites: [],
    categories: [],
    searchStoreQuery: "",
    searchPromocodeQuery: "",
    splashScreen: false,
    completeGuide: false
}

function rootReducer(state = initialState, action: any) {

    if (action.type === RECEIVE_DATA) {
        const { data } = action
        return Object.assign({}, state, {
            favorites: data.favorites,
            shopsList: data.list,
            appStatus: "ready"
        })
    }

    if (action.type === UPDATE_SEARCH_PROMOCODE_QUERY) {
        return Object.assign({}, state, {
            searchPromocodeQuery: action.searchPromocodeQuery
        })
    }

    if (action.type === UPDATE_SEARCH_STORE_QUERY) {
        return Object.assign({}, state, {
            searchStoreQuery: action.searchStoreQuery
        })
    }

    if (action.type === UPDATE_SPLASH_SCREEN_VISIBILITY) {
        return Object.assign({}, state, {
            splashScreen: action.splashScreen
        })
    }

    if (action.type === UPDATE_APP_SETTINGS) {
        return Object.assign({}, state, action.appSettings)
    }

    if (action.type === UPDATE_APP_GUIDE_COMPLETE) {
        return Object.assign({}, state, {
            completeGuide: action.completeGuide
        })
    }

    if (action.type === UPDATE_DATA_CATEGORIES) {
        return Object.assign({}, state, {
            categories: action.categories.sort()
        })
    }

    return state
}

export default rootReducer;
