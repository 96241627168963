import { IStore } from "./IStore";

const { LocalStorage } = require("ttl-localstorage")

export default class PermanentStorage implements IStore {
    private prefix: string = 'arockets'

    public async set(key: string, value: string | object): Promise<any> {
        const storeKey = this.generateStoreKey(key)
        return await LocalStorage.put(storeKey, value, null)
    }

    public async get(key: string): Promise<any> {
        const storeKey = this.generateStoreKey(key)
        return await LocalStorage.get(storeKey)
    }

    private generateStoreKey(key: string): string {
        return `${this.prefix}:${key}`
    }
}

export const permanentStorage = new PermanentStorage()
