import React from 'react';
import { Container, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import ArocketsIcon from "./ArocketsIcon";

const useStyles = makeStyles((theme: Theme) => ({
    logo: {
        display: "block",
        height: 40,
        marginBottom: 8,
        marginTop: 20,
        marginLeft: "auto",
        marginRight: "auto"
    }
}))

const FooterComponent = () => {
    const classes = useStyles()
    return (
        <Container maxWidth="md">
            <Link href="/">
                <ArocketsIcon className={classes.logo}/>
            </Link>
            <Typography variant="caption" display="block" component="span" align="center">
                © All rights reserved
                <br/> Made by &nbsp;
                <Link href="https://velie.ru?ref=app.arockets.ru"
                      target="_blank"
                      underline="none">
                    velie.ru
                </Link>
            </Typography>
        </Container>
    )
}

export default FooterComponent
