const promocodeFound = (n: number) => {
    return ending(n) === 0 ? "Найден" : "Найдено"
}

const ending = (n: number) => {
    return n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
}

const filterShops = (list: any[] = [], query: string = ""): any[] => {
    return list.filter((item: any) => {
        return (item.name + item.host + (item.category || []).join(" ")).toLowerCase().indexOf(query.toLowerCase()) > -1
    })
}

export {
    promocodeFound,
    filterShops
}

