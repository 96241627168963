import React, { ErrorInfo } from "react";


class ErrorBoundary extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            hasError: props.hasError || false
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            hasError: true
        })
    }

    render() {
        if (this.state.hasError) {
            return (
                <React.Fragment>
                    Что то пошло не так,<br/>попробуйте повторить позже
                </React.Fragment>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary
