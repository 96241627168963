import { createMuiTheme } from "@material-ui/core";
import green from "@material-ui/core/colors/green";

const themeTiktok = createMuiTheme({
    palette: {
        primary: {
            main: '#69C9D0',
            // main: green[300]
        },
        secondary: {
            main: green[500],
        },
        success: {
            main: "#EE1D52"
        }
    },
    overrides: {
        MuiLink: {
            root: {
                '-webkit-tap-highlight-color': 'rgba(0,0,0,0.02)'
            },
        },
        MuiPaper: {
            rounded: {
                borderRadius: 24
            }
        },
        MuiButton: {
            root: {
                borderRadius: 15,
            },
            containedPrimary: {
                color: "white"
            }
        },
        MuiCard: {
            root: {
                borderRadius: 24
            }
        },
        MuiInputBase: {
            root: {
                borderRadius: 15
            }
        }
    },
});

export {
    themeTiktok
}
