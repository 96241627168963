import store from "./index";
import { receiveData, updateAppSettings, updateCategories } from "./actions";
import { fetchShopList, getAppSettings } from "./utils";

export default async () => {
    const data = await fetchShopList()
    const { list = [] } = data
    const categories = new Set<string>()
    list.forEach((item: any) => {
        (item.category || []).forEach((category: string) => {
            categories.add(category.charAt(0).toUpperCase() + category.slice(1).toLowerCase())
        })
    })
    const appSettings = await getAppSettings()
    store.dispatch(updateAppSettings(appSettings))
    store.dispatch(receiveData(data))
    store.dispatch(updateCategories([...Array.from(categories), "Разное"].filter((item => !!item))))
}
