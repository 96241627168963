import { Theme } from "@material-ui/core";

export const pageMainTitle = (theme: Theme) => ({
    pageMainTitle: {
        color: 'white',
        borderRadius: '0 0 15% 3%',
        paddingBottom: 120,
        backgroundColor: theme.palette.primary.main
    }
})

export const pageContent = (theme: Theme) => ({
    pageContent: {
        marginTop: -100,
        minHeight: 200,
    }
})
