import React, { MouseEventHandler } from 'react';
import {
    Avatar,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    makeStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { connect } from "react-redux";
import ArocketsIcon from "../ArocketsIcon";
import StoreIcon from '@material-ui/icons/Store';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import { Link as RouterLink } from 'react-router-dom';
import { locationCategory } from "../../store/utils";
import ReactGA from "react-ga";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        maxWidth: 380,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
    },

    account: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: theme.spacing(2, 2.5, 2, 2.5),
        margin: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        background: theme.palette.grey[200]

    }
}))

const mapStateToProps = (state: any) => {
    return {
        categories: state.categories,
    };
};

interface IProp {
    categories?: string[],
    location: any,
    onClick?: MouseEventHandler
}

const NavBar = (props: IProp) => {
    const classes = useStyles();
    const categories: string[] = props.categories || []
    const category = locationCategory(props.location) || ""

    const onClick = (evt: any, label: string) => {
        ReactGA.event({
            category: "category",
            action: "click-menu",
            label: label
        })
        props.onClick && props.onClick(evt)
    }

    return (
        <React.Fragment>
            <List
                component="nav"
                disablePadding
                className={classes.root}
            >
                <ListItem>
                    <Box mt={2} margin="auto">
                        <RouterLink to="/">
                            <ArocketsIcon/>
                        </RouterLink>
                    </Box>
                </ListItem>
                <ListItem>
                    <div className={classes.account}>
                        <Avatar alt="aRockets"
                                src="https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png"/>
                        <Box ml={2}>
                            <Typography variant="subtitle2" color="textPrimary">
                                Пользователь
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                любитель
                            </Typography>
                        </Box>
                    </div>
                </ListItem>
                <ListSubheader>
                    Магазины
                </ListSubheader>
                <ListItem
                    to={`/`}
                    color="inherit"
                    component={RouterLink}
                    onClick={(evt: any) => {
                        onClick(evt, "Все")
                    }}
                    selected={category === ""}
                    button
                >
                    <ListItemIcon>
                        <StoreIcon/>
                    </ListItemIcon>
                    <ListItemText disableTypography primary="Все"/>
                </ListItem>
                <ListSubheader>
                    Категории
                </ListSubheader>
                {categories && categories.map(item => (
                    <ListItem
                        key={item}
                        to={`/?category=${item}`}
                        color="inherit"
                        onClick={(evt: any) => {
                            onClick(evt, item)
                        }}
                        selected={item.toLowerCase() === category.toLowerCase()}
                        component={RouterLink}
                        button
                    >
                        <ListItemIcon>
                            <HorizontalSplitIcon/>
                        </ListItemIcon>
                        <ListItemText disableTypography primary={item}/>
                    </ListItem>
                ))}
            </List>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, null)(NavBar)
