import React from 'react'
import { Avatar, Card, CardHeader, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import ImageLoader from "../components/ImageLoader";
import { prepareHost } from "../store/utils";

const useStyles = makeStyles((theme: Theme) => ({
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    subtitle: {
        overflow: "hidden"
    }
}))

const StorePreview = (props: any) => {
    const classes = useStyles()

    return (
        <Card elevation={props.elevation}
              variant={props.elevation ? "elevation" : "outlined"}>
            <Link
                underline="none"
                href={`/store/${prepareHost(props.host)}`}
            >
                <CardHeader
                    classes={{
                        content: classes.subtitle
                    }}
                    avatar={
                        <Avatar
                            className={classes.large}
                            variant="rounded"
                            alt={props.host}
                            src={ImageLoader.defaultImage}
                            imgProps={{
                                // @ts-ignore
                                "data-src": props.image || ImageLoader.defaultImage,
                                style: {
                                    maxWidth: "100%",
                                    maxHeight: "60px",
                                    height: "auto",
                                },
                                className: "image-loading"
                            }}
                        />
                    }
                    title={props.name}
                    titleTypographyProps={{
                        variant: "subtitle1",
                        style: {
                            fontWeight: "bold"
                        },
                        noWrap: true
                    }}
                    subheader={
                        <React.Fragment>
                            <Typography noWrap>
                                {props.host}
                            </Typography>
                            <Typography variant="caption" noWrap>
                                {props.category.join(', ')}
                            </Typography>
                        </React.Fragment>
                    }
                />
            </Link>
        </Card>
    )
}

export default StorePreview
