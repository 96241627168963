const HOST = "https://arockets.ru"

export const getShopsData = async () => {
    const url = `${HOST}/data/stores/data.json`
    const res = await fetch(url)
    return await res.json()
}

export const getStoreData = async (uuid: string) => {
    const url = `${HOST}/data/stores/${uuid}.json`
    const res = await fetch(url)
    return await res.json()
}
