import { IStore } from "./IStore";

const { LocalStorage } = require("ttl-localstorage")
const TTL_DEFAULT = 12 * 60 * 60 //sec

export default class CacheStorage implements IStore {
    private prefix: string = 'arockets'

    constructor(private timeout: number = TTL_DEFAULT) {
    }

    public async set(key: string, value: string | object, timeout: number = this.timeout): Promise<any> {
        const storeKey = this.generateStoreKey(key)
        return await LocalStorage.put(storeKey, value, timeout)
    }

    public async get(key: string): Promise<any> {
        const storeKey = this.generateStoreKey(key)
        return await LocalStorage.get(storeKey)
    }

    private generateStoreKey(key: string): string {
        return `${this.prefix}:${key}`
    }
}

export const cashStorage = new CacheStorage()
