import React from 'react';
import Joyride, { EVENTS } from 'react-joyride';
import { themeTiktok } from '../../theme/index'
import { bindActionCreators } from "redux";
import { completeGuide } from "../../store/actions";
import { connect } from "react-redux";
import ReactGA from "react-ga";

const steps = [
    {
        target: '#arockets-logo',
        disableBeacon: true,
        content: 'Мы рады этой встрече, мы собрали более 10000 промокодов для вас! Нажмите на красный маячок, мы расскажем как пользоваться приложением aRockets.',
    },
    {
        target: '#search',
        disableBeacon: true,
        content: 'Поиск магазина в каталоге aRockets или по промокодам в текущем магазине',
    },
    {
        target: '#toolbar-menu',
        disableBeacon: true,
        content: 'Магазины разбиты по категориям для быстрой навигации',
    },
    {
        target: '#toolbar',
        disableBeacon: true,
        content: 'Давайте покупать выгодно! Добавьте приложение себе в избранное',
    },
]

const mapStateToProps = (state: any) => {
    return {
        completeGuide: state.completeGuide,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        completeGuideDispatch: completeGuide
    }, dispatch)
}

interface IProps {
    completeGuide: boolean,
    completeGuideDispatch: any
}

const Guide = (props: IProps) => (
    <Joyride steps={steps}
             continuous
             styles={{
                 buttonNext: {
                     backgroundColor: themeTiktok.palette.secondary.main,
                 },
                 buttonClose: {
                     color: themeTiktok.palette.secondary.main,
                     display: "none"
                 },
                 buttonSkip: {
                     color: themeTiktok.palette.secondary.main,
                 },
                 buttonBack: {
                     display: "none"
                 },
                 options: {
                     zIndex: 10000,
                 }
             }}
             callback={({ type }) => {
                 if (type === EVENTS.TOUR_END) {
                     ReactGA.event({
                         category: "guide",
                         action: "complete"
                     })
                     props.completeGuideDispatch(true)
                 }
             }}
             run={!props.completeGuide}
             locale={{
                 next: "Понятно",
                 skip: "Пропустить",
                 last: "Отлично"
             }}
             scrollToFirstStep
             showProgress
             disableCloseOnEsc
             disableOverlayClose
             showSkipButton={true}/>
)

export default connect(mapStateToProps, mapDispatchToProps)(Guide)
