import React from 'react'
import { Box, Button, Card, CardActions, CardContent, Grid, makeStyles, Theme, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
    area: {
        minHeight: 120,
        height: '100%',
    },
    gridItem: {
        flexBasis: 0
    },
    buttonLabel: {
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
}))


const CustomPromocodeView = (props: any) => {
    const classes = useStyles()

    return (
        <React.Fragment>
            <Card elevation={1} className={classes.area}>
                <Grid container direction="column" justify="flex-end" alignItems="stretch" style={{
                    height: '100%'
                }}>
                    <React.Fragment>
                        <Grid className={classes.gridItem} item sm={12} style={{
                            flex: 2
                        }}>
                            <CardContent>
                                <Typography variant="subtitle1" gutterBottom>
                                    <Box fontWeight="fontWeightBold" mt={1} mb={1}>
                                        Поделиться промокодом
                                    </Box>
                                </Typography>
                            </CardContent>
                        </Grid>
                        <Grid className={classes.gridItem} item sm={12}>
                            <CardContent>
                                У Вас есть промокод, который можно применить?
                                Поделитесь с нашими пользователями или напишите нам Ваши пожелания :)
                            </CardContent>
                        </Grid>
                        <Grid className={classes.gridItem} item sm={12}>
                            <CardActions>
                                <Box pl={1} pb={1} pt={1} pr={1} width={1}>
                                    <Button fullWidth variant="outlined"
                                            href="mailto:promocodes@arockets.ru?subject=Поделиться промокодом"
                                            color="primary"
                                            rel="noopener noreferrer">
                                        Написать
                                    </Button>
                                </Box>
                            </CardActions>
                        </Grid>
                    </React.Fragment>
                </Grid>
            </Card>
        </React.Fragment>
    )
}

export default CustomPromocodeView
