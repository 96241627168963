import lozad from "lozad";

class ImageLoader {
    public readonly observer: lozad.Observer

    static defaultImage: string = "/no-photo.svg"

    constructor(selector: string) {
        this.observer = lozad(selector, {
            loaded(el: any) {
                try {
                    const img = new Image();
                    img.src = el.getAttribute('data-src');
                    img.addEventListener('error', (e) => {
                        el.src = ImageLoader.defaultImage
                    }, false);
                } catch (e) {
                }
            }
        });
    }

    public observe() {
        setTimeout(() => {
            this.observer.observer.disconnect()
            this.observer.observe()
        }, 1)
    }
}

export default ImageLoader
