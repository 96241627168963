import React from 'react';
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as ArocketsLogo } from '../assets/arockets-logo.svg';

const ArocketsIcon = (props: any) => (
    <SvgIcon {...props} color="action">
        <ArocketsLogo/>
    </SvgIcon>
)

export default ArocketsIcon
