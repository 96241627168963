import React from 'react';
import { Box, Button, Grid, Grow, Link, Typography } from "@material-ui/core";
import CenterContent from "../components/CenterContent";

const NotFound = () => (
    <CenterContent>
        <Grid container spacing={1} justify="center">
            <Grid item xs={12}>
                <Typography align="center" variant="h6" component="h3">
                    <Box fontWeight="fontWeightBold">
                        Страница не найдена
                    </Box>
                </Typography>
                <Typography align="center" variant="subtitle1" gutterBottom>
                    Возможно страница переехала или не существует, Вы можете посмотреть наш каталог или
                    написать
                    нам, если у Вас возникли реальные проблемы
                </Typography>
                <Typography align="center" gutterBottom>
                    <Link href="mailto:support@arockets.ru">
                        support@arockets.ru
                    </Link>
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Grow in={true}
                      timeout={500}
                >
                    <img width="100%" src="/404.svg" alt="404"/>
                </Grow>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} justify="center">
                    <Grid item>
                        <Button variant="contained" style={{ marginBottom: 3 }} color="primary" href="/">
                            Каталог
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </CenterContent>
)

export default NotFound
