import React from 'react';
import { AppBar, IconButton, Link, makeStyles, Slide, Toolbar, Typography, useScrollTrigger } from "@material-ui/core";
import ToolbarSearch from "./ToolbarSearch";
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => {
    return {
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
            marginRight: 16,
            fontWeight: "bold",
            color: "white"
        },
        toolbar: theme.mixins.toolbar,
    }
});

interface Props {
    window?: () => Window;
    children: React.ReactElement;
}

function HideOnScroll(props: Props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({ target: window ? window() : undefined });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

const ToolbarComponent = (props: any) => {
    const classes = useStyles();

    return (
        <HideOnScroll {...props}>
            <AppBar position="sticky" elevation={0} id="toolbar">
                <Toolbar>
                    <IconButton
                        onClick={props.setOpen}
                        className={classes.menuButton}
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                    >
                        <MenuIcon id="toolbar-menu"/>
                    </IconButton>
                    {/*<ArocketsIcon/>*/}
                    <Typography variant="h6" className={classes.title}>
                        <Link href="/" underline="none" color="inherit" id="arockets-logo">
                            aRockets
                        </Link>
                    </Typography>
                    {props.showToolbarSearch !== false && (
                        <ToolbarSearch {...props}/>
                    )}
                </Toolbar>
            </AppBar>
        </HideOnScroll>
    )
}

export default ToolbarComponent
