import React, { useState } from 'react';
import { Container, Drawer, Hidden, SwipeableDrawer } from "@material-ui/core";
import Toolbar from "../components/navigation/Toolbar";
import NavBar from "../components/navigation/NavBar";

const ToolbarLayout = (props: any) => {
    const [open, setOpen] = useState(false)
    const toggleDrawer = (open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        setOpen(open);
    };

    return (
        <React.Fragment>
            <Container disableGutters maxWidth="md">
                <Toolbar {...props}
                         setOpen={() => {
                             setOpen(!open)
                         }}/>
                <Hidden xlUp>
                    <SwipeableDrawer
                        anchor="left"
                        variant="temporary"
                        open={open}
                        onClose={toggleDrawer(false)}
                        onOpen={toggleDrawer(true)}
                    >
                        <NavBar onClick={toggleDrawer(false)} location={props.location}/>
                    </SwipeableDrawer>
                </Hidden>
                <Hidden lgDown>
                    <Drawer
                        open
                        anchor="left"
                        variant="permanent"
                    >
                        <NavBar location={props.location}/>
                    </Drawer>
                </Hidden>
                {props.children}
            </Container>
        </React.Fragment>
    )
}

export default ToolbarLayout
