import React from 'react';
import { Box, Container } from "@material-ui/core";

interface IProps {
    children: JSX.Element | String | JSX.Element[],
    height?: any,
    style?: any
}

const CenterContent = (props: IProps) => (
    <Box overflow="hidden" style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0
    }}>
        <Box overflow="hidden" display="flex" alignItems="center" justifyContent="center"
             height={props.height === false ? 'auto' : props.height || '100%'}>
            <Box m="auto" overflow="hidden">
                <Container disableGutters maxWidth="sm">
                    {props.children}
                </Container>
            </Box>
        </Box>
    </Box>
)

export default CenterContent
