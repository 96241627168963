import { cashStorage } from "./stores/CacheStorage";
import { AppSettings } from "./actions";
import { getShopsData } from "../api/arockets";
import { permanentStorage } from "./stores/PermanentStorage";
import { IStore } from "./stores/IStore";

const storeListKey = 'shop:list'

export const getStoreShopList = async () => {
    return await cashStorage.get(storeListKey)
}

export const getAppSettings = async (): Promise<AppSettings> => {
    const splashScreen = await cashStorage.get('app:settings:splashScreen')
    const completeGuide = await permanentStorage.get("app:settings:completeGuide")
    return {
        splashScreen: !!splashScreen,
        completeGuide: !!completeGuide
    }
}

const setStoreShopList = async (list: any[]) => {
    return cashStorage.set(storeListKey, list)
}

const setAppSettings = async (settings: AppSettings, store: IStore = cashStorage): Promise<void> => {
    const items = Object.entries(settings)
    for await (let item of items) {
        const key: string = item[0]
        const value: any = item[1]
        //12H
        await store.set(`app:settings:${key}`, value)
    }
}

export const setAppPermanentsSettings = async (settings: AppSettings): Promise<void> => {
    await setAppSettings(settings, permanentStorage)
}
export const setAppCacheSettings = async (settings: AppSettings): Promise<void> => {
    await setAppSettings(settings, cashStorage)
}

export const locationCategory = (location: any) => {
    const query = new URLSearchParams(location.search)
    return query.get("category")
}


export const fetchShopList = async (): Promise<any> => {
    const cachedList = await getStoreShopList()
    if (cachedList?.list?.length > 0) {
        return cachedList
    }
    const data = await getShopsData()
    await setStoreShopList(data as any)
    return data
}

export const prepareHost = (host: string): string => {
    return host.split("/").reverse().join(".")
}
